document.addEventListener("DOMContentLoaded", function(event) {
    $("#contactForm1").submit(function(e) {
        e.preventDefault(); // avoid to execute the actual submit of the form.

        var form = $(this);
        var url = form.attr('action');

        $('#contactForm1 button').prop('disabled', true);

        $.ajax({
            type: "POST",
            url: url,
            data: form.serialize(), // serializes the form's elements.
            success: function (data) {
                if (data.success) {
                    $('#contactForm1 label').removeClass('error');
                    $('#contactForm1 button').prop('disabled', true).hide();
                    $('#contactForm1 input').prop('disabled', true);
                    $('#contactForm1 .success').css('display', 'block');

                    $('#form_name div').html('');
                    $('#form_email div').html('');
                    $('#form_phone div').html('');
                    //alert('Завяка принята!')
                } else {
                    //alert('Ошибка');

                    $('#contactForm1 button').prop('disabled', false);

                    if (data.errors) {
                        var isFocused = false;
                        if (data.errors.name) {
                            $('#form_name').addClass('error');
                            $('#form_name div').html(data.errors.name);

                            if (!isFocused) {
                                $('#form_name input').focus();
                                isFocused = true;
                            }
                        } else {
                            $('#form_name').removeClass('error');
                            $('#form_name div').html('');
                        }

                        if (data.errors.email) {
                            $('#form_email').addClass('error');
                            $('#form_email div').html(data.errors.email);

                            if (!isFocused) {
                                $('#form_email input').focus();
                                isFocused = true;
                            }
                        } else {
                            $('#form_email').removeClass('error');
                            $('#form_email div').html('');
                        }

                        if (data.errors.phone) {
                            $('#form_phone').addClass('error');
                            $('#form_phone div').html(data.errors.phone);

                            if (!isFocused) {
                                $('#form_phone input').focus();
                                isFocused = true;
                            }
                        } else {
                            $('#form_phone').removeClass('error');
                            $('#form_phone div').html('');
                        }
                    }

                    //console.log(data);
                }
            },
            error: function (data) {
                console.log('An error occurred.');
                console.log(data);
            },
        });
    });
});
